import { lazy, Suspense } from 'react'
import Hero from 'src/pages/VoltNet/components/Hero'

const Branding = lazy(() => import('src/pages/VoltNet/components/Branding'))
const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection'))
const Compatibility = lazy(() => import('src/_shared/components/Compatibility'))
const Ecosystem = lazy(() => import('src/pages/VoltNet/components/Ecosystem'))
const Visibility = lazy(() => import('src/pages/VoltNet/components/Visibility'))

export const VoltNet = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Ecosystem />
				<Branding />
				<Visibility />
				<Compatibility page={'VoltNet'} />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
