import { lazy, Suspense } from 'react'
import Hero from 'src/pages/VoltPos/components/Hero'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection'))
const Compatibility = lazy(() => import('src/_shared/components/Compatibility'))
const CustomerSatisfaction = lazy(() => import('src/pages/VoltPos/components/CustomerSatisfaction'))
const Features = lazy(() => import('src/pages/VoltPos/components/Features'))
const Payments = lazy(() => import('src/pages/VoltPos/components/Payments'))

export const VoltPos = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Features />
				<Compatibility page="VoltPos" />
				<Payments />
				<CustomerSatisfaction />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
