import { lazy, Suspense } from 'react'
import Hero from 'src/pages/Media/components/Hero'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection'))
const NewsRoom = lazy(() => import('src/pages/Media/components/NewsRoom'))

export const Media = (): JSX.Element => {
	return (
		<div>
			<Hero />
			<Suspense fallback={<></>}>
				<NewsRoom />
				<CallToActionSection />
			</Suspense>
		</div>
	)
}
