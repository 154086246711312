import { PageRoutePath } from 'src/App/routing'

export interface HeaderLink {
	text: string
	url: PageRoutePath
}

export const HEADER_LINKS: HeaderLink[] = [
	{
		text: 'VoltNet',
		url: PageRoutePath.VoltNet
	},
	{
		text: 'VoltNow',
		url: PageRoutePath.VoltNow
	},
	{
		text: 'VoltPOS',
		url: PageRoutePath.VoltPos
	},
	{
		text: 'About Us',
		url: PageRoutePath.AboutUs
	},
	{
		text: 'Contact Us',
		url: PageRoutePath.ContactUs
	},
	{
		text: 'Media',
		url: PageRoutePath.Media
	}
]
