import VoltNetLogo from 'src/_shared/components/_icons/VoltNetLogo'

const Hero = (): JSX.Element => {
	return (
		<div className="relative h-screen">
			{/* Hero Image */}
			<picture>
				<source media="(max-width: 511px)" srcSet="/assets/voltnet_page/hero/511w_1x.jpg" />
				<source
					media="((min-width: 512px) and (max-width: 1024px))"
					srcSet="/assets/voltnet_page/hero/1024w_1x.jpg"
				/>
				<img
					loading="lazy"
					src="/assets/voltnet_page/hero/1440w_1x.jpg"
					alt="Hero image"
					className="absolute inset-0 h-full w-full object-cover"
				/>
			</picture>
			<div className="relative z-10 flex h-full flex-col items-center justify-center space-y-4 px-10 text-center text-white md:px-20">
				<VoltNetLogo className="w-48" />
				<div className="mb-6 font-heading text-heading-3 leading-none tracking-tight lg:text-heading-2">
					Supercharge Your Network
				</div>
				<div className="mb-3 text-paragraph">
					Expand your ecosystem of charging stations and drivers together.
				</div>
			</div>
		</div>
	)
}

export default Hero
