import { lazy, Suspense } from 'react'
import Hero from 'src/pages/Main/components/Hero'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection'))
const Customers = lazy(() => import('src/pages/Main/components/Customers'))
const Network = lazy(() => import('src/pages/Main/components/Network'))
const Partners = lazy(() => import('src/pages/Main/components/Partners'))
const Services = lazy(() => import('src/pages/Main/components/Services'))
const Statistics = lazy(() => import('src/pages/Main/components/Statistics'))

export const Main = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Partners />
				<Customers />
				<Network />
				<Services />
				<Statistics />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
